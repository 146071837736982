.stash {
  z-index: 4;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: -15px;
  background: #55323c;

  h4 {
    margin-top: 0;
  }

  .toggle-show-commit-diffs {
    display: inline-block;
  }

  .diff-wrapper {
    margin-top: 5px;
  }
}

.stash-toggle {
  width: 120px;
  height: 30px;
  position: relative;
  background: #55323c;
  left: 20px;
  border-radius: 5px 5px 0 0;
  padding-top: 5px;
  text-align: center;
}

.stash-toggle-text {
  cursor: pointer;
}

.expand-icon {
  opacity: 0.5;
}

.stash-apply .octicon {
  vertical-align: middle;
}
